moment.locale('fr');

let fr_tooltip_datepick = {
  today: `Aller à aujourd'hui`,
  clear: 'Vider',
  close: 'Fermer',
  selectMonth: 'Choix du mois',
  prevMonth: 'Mois précédent',
  nextMonth: 'Mois suivant',
  selectYear: 'Choix année',
  prevYear: 'Année précédente',
  nextYear: 'Année suivante',
  selectDecade: 'Choix décennie',
  prevDecade: 'Décennie précédente',
  nextDecade: 'Décennie suivante',
  prevCentury: 'Siècle précédent',
  nextCentury: 'Siècle suivant'
};

// alert, dialog, confirm
jconfirm.defaults = {
  title: 'Info',
  content: 'Continuer?',
  confirmButton: 'OK',
  cancelButton: 'Annuler',
  confirmButtonClass: 'btn-primary',
  cancelButtonClass: 'btn-default',
  theme: 'white',
  animation: 'opacity',
  closeAnimation: 'scale',
  animationSpeed: 200,
  animationBounce: 1.2,
  keyboardEnabled: true,
  container: 'body',
  closeIcon: true,
  backgroundDismiss: true,
  columnClass: 'col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1',
};

// Dropzone
Dropzone.autoDiscover = false;

var _uploadConfig = {
  dictDefaultMessage: "Cliquez ou glissez votre fichier",
  dictFallbackMessage: "Glissez déposez non supporté.",
  dictFallbackText: null,  
  dictInvalidFileType: "type de fichier non supporté.",
  dictFileTooBig: "Taille de fichier trop grande @{{maxFilesize}}",
  dictResponseError: "Erreur serveur: @{{statusCode}}",
  dictCancelUpload: "Interrompre",
  dictCancelUploadConfirmation:  "Interrompre le chargement ?",
  dictRemoveFile: "Masquer",
  dictMaxFilesExceeded: "Nombre de fichiers maximum atteint",
  addRemoveLinks: true,
};


// param. globaux de toast
$.toast.options.position = 'bottom-left';

// Bootgrid , param par défaut
var _grid_label = {

  all: "Tous",
  infos: '<span class="label label-warning">{{ctx.start}} à {{ctx.end}} sur {{ctx.total}}</span>',
  loading: "Chargement...",
  noResults: "...",
  refresh: "Actualiser",
  search: "Recherche"

};

// param de visu de la list (par 15, 30, ou tous)



var _grid_rowcount =  [15, 30, -1];
if($( window ).height() > 900)
  _grid_rowcount =  [20, 40, -1];

function _grid_formatters(crud_rights) {

  if(!crud_rights) crud_rights = "";

  return {
    "commands": function(column, row) {
                    if (crud_rights.indexOf("u") != -1 && (row.locked == 'undefined' || !row.locked))
                      buttons = "<button type='button' title='Editer' class='tips btn btn-xs btn-primary command-edit' data-row-id='" + row.rid + 
                                  "'><span class='glyphicon glyphicon-pencil'></span></button> ";
                    else
                      buttons = "<button type='button' title='Visualiser' class='tips btn btn-xs btn-primary command-edit' data-row-id='" + row.rid + 
                                "'><i class='fa fa-file-text-o'></i></button> ";

                    if (crud_rights.indexOf("d") != -1 && (row.locked == 'undefined' || !row.locked))
                      buttons = buttons + "<button type='button' title='Supprimer' class='tips btn btn-xs btn-warning command-delete' data-row-id='" + 
                                row.rid + "'><span class='glyphicon glyphicon-trash'></span></button> ";

                    if (crud_rights.indexOf("t") != -1) 
                      buttons = buttons + "<button type='button' title='Dupliquer' class='tips btn btn-xs btn-warning command-twin' data-row-id='" + 
                                row.rid + "'><span class='glyphicon glyphicon-duplicate'></span></button> ";

                    return buttons;                           
                },
    "tel":  function(column, row) {
              tel = row[column.id];
              if (!tel || (tel.length != 10 && tel.length != 13 && tel.length != 14)) return tel;

              if (tel.length == 10) // format national
                return  tel.substring(0, 2) + "." + tel.substring(2, 4) + "." + tel.substring(4, 6) + "." + 
                        tel.substring(6, 8) + "." + tel.substring(8);
              if (tel.length == 13) // format international
                return  tel.substring(0, 4) + "." + tel.substring(4, 5) + "." + tel.substring(5, 7) + "." + tel.substring(7, 9) + "." + 
                        tel.substring(9, 11) + "." + tel.substring(11);

              // format international avec indicatif XXX
              return  tel.substring(0, 5) + "." + tel.substring(5, 6) + "." + tel.substring(6, 8) + "." + tel.substring(8, 10) + "." + 
                      tel.substring(10, 12) + "." + tel.substring(12);
    },
    "yes_no":  function(column, row) {
              sws = $.trim(row[column.id]).split(',');

              booleans = [];
              sws.forEach(sw => {
                if (sw != '') {
                  if (sw == 1) booleans.push('<b>Oui</b>');
                  if (sw == 0) booleans.push('Non');
                }
              });           
              
              if(booleans.length == 0) return row[column.id];
              return booleans.join(', ');
    },
    "check":  function(column, row) {
              sw = row[column.id];
              if (sw != 0) return '<i class="fa fa-check fa-bs"></i>';              
              
              return '';
    },    
    "u_o_m":  function(column, row) {
              sw = row[column.id];
              if (sw == 'U') return '<i class="fa fa-question fa-bs"></i>';
              if (sw == 'O') return '<i class="fa fa-plus  fa-bs"></i>';
              if (sw == 'M') return '<i class="fa fa-check fa-bs"></i>';
              return sw;
    },
    "icon":  function(column, row) {
              icon_class = row[column.id];
              if (!icon_class) return '';

              title = '';
              if (row.icon_title) title = 'title="' + row.icon_title + '"';

              return '<i ' + title + ' class="' + icon_class + ' tips" style="display: inline;"></i>';     
    },
    "status":  function(column, row) {
              sw = row[column.id];
               if (sw == 'na' || sw == 'na_long' ) return '<i title="Statut externe - Non actif" class="fa fa-plug fa-bs tips" style="display: inline; color: gray;"></i>';
               if (sw == 'on')       return '<i title="Statut externe - En ligne" class="fa fa-plug fa-bs tips" style="display: inline; color: green;"></i>';
               if (sw == 'off')      return '<i title="Statut externe - Hors ligne" class="fa fa-plug fa-bs tips" style="display: inline; color: orange;"></i>';
               if (sw == 'off_long') return '<i title="Statut externe - Hors ligne 3J+" class="fa fa-plug fa-bs tips" style="display: inline; color: red;"></i>';
               if (sw == 'old_status') return '<i title="Statut externe - Ancien 1H+" class="fa fa-plug fa-bs tips" style="display: inline; color: #df00ff82;"></i>';
               if (sw == 'old_status_long') return '<i title="Statut externe - Ancien 1H+" class="fa fa-plug fa-bs tips" style="display: inline; color: #df00ff82;"></i>';
               return sw;
    },
    "modal_url": function(column, row) {
      id = row[column.id];
      if (!column.fmtParam) return id;

              if(typeof column.fmtParam === 'string') {
                return "<button onclick='openInGlobalModal(this, event)' href=\"" + column.fmtParam + "/" + id + "\" type='button' class='tips btn btn-xs btn-primary'><span class='glyphicon glyphicon-modal-window'></span></button>";
              }
              else {
                url = column.fmtParam.url?column.fmtParam.url:"";
                icon = column.fmtParam.icon?column.fmtParam.icon:"glyphicon-modal-window";
                title = column.fmtParam.title?column.fmtParam.title:"Ouvrir le lien";                
                return "<button onclick='openInGlobalModal(this, event)' href=\"" + url + "/" + id + "\" type='button' class='tips btn btn-xs btn-primary' title='"+title+"'><span class='glyphicon "+icon+"'></span></button>";
              }
    },
    "google_map": function(column, row) {
      text = row[column.id].split(',');
      console.log(row, text);
      url = column.fmtParam.url?column.fmtParam.url:"";
      icon = column.fmtParam.icon?column.fmtParam.icon:"glyphicon glyphicon-globe";

      if(text.length < 2) return '';

      return `
        <div style='display: grid; grid-template-columns: 70px 15px; grid-gap: 5px'>
          <div>
            <div style='font-size: 10px; line-height: 12px;'>${text[0]}</div>
            <div style='font-size: 10px; line-height: 11px;'>${text[1]}</div>
          </div>
          <div>
            <a href="https://www.google.com/maps/dir/${text}" target=_blank>
              <button class='tips btn btn-xs btn-success' title='Visualiser sur google map'><span class='glyphicon ${icon}'></span></button>
            </a>
          </div>
        </div>`;
    },
    "url":  function(column, row) {
              url = row[column.id];
              if (!url) return url;

              urlDefault = column.fmtParam && column.fmtParam.url?column.fmtParam.url:false;
              if (urlDefault) url = urlDefault.replace('{value}', url);

              label = column.fmtParam && column.fmtParam.icon?"<button class='tips btn btn-xs btn-primary'><span class='glyphicon "+column.fmtParam.icon+"'></span></button>":url;
              title = column.fmtParam && column.fmtParam.title?column.fmtParam.title:"Ouvrir le lien";

              return "<a href=\"" + url + "\" target=_blank title='" + title + "'>" + label + "</a>";
    },
    "email":  function(column, row) {
              email = row[column.id];
              if (!email) return email;

              return "<a href=\"mailto:" + email + "\">" + email + "</a>";
    },
    "diff_report": function(column, row) {
      let col_diff = row[column.fmtParam.col_diff];
      let col_cur = row[column.id];
      let context_id = row[column.fmtParam.context_id];
      let col_text =  col_cur - col_diff;

      if(column.fmtParam.url) col_text = `<a target='_blank' href="${column.fmtParam.url}?context_id=${context_id}">${col_text}</a>`;
      return col_text;

    },
    "compare_report": function(column, row) {
      let col_comp = row[column.fmtParam.col_comp];
      let col_cur = row[column.id];
      let context_id = row[column.fmtParam.context_id];

      if(column.fmtParam.constructor === ({}).constructor) {
        if(col_cur > col_comp) col_text = `<span title='diff. ${col_cur - col_comp}'>${col_cur} <img src='/media/icon/mini_up_green.png', 'title'='Supérieur'> </span>`;
        else if(col_cur < col_comp) col_text = `<span title='diff. ${col_cur - col_comp}'>${col_cur} <img src='/media/icon/mini_down_red.png', 'title'='Inférieur'></span>`;
        else if(col_cur = col_comp) col_text = `<span>${col_cur} =</span>`;
        else col_text = col_cur;

        if(column.fmtParam.url) col_text = `<a target='_blank' href="${column.fmtParam.url}?context_id=${context_id}">${col_text}</a>`;

        return col_text;
      }
      else {
        console.log(column.fmtParam);
        return 'Err.Param';
      }
    },
    "entity": function(column, row) {
              text = $.trim(row[column.id]);
              ids = text.split(',');
              if (!ids || !ids.length || !column.fmtParam) return text;

              urls = [];
              ids.forEach(id => {
                id = $.trim(id);
                if (!id) return urls.push(id);

                if (typeof column.fmtParam === 'string') {
                  url = column.fmtParam;
                  urls.push("<a href=\"" + url + "/" + id + "\" target=_blank class='tips' title='Ouvrir la fiche'>" + id + "</a>"); 
                } else {
                  url = column.fmtParam.url?column.fmtParam.url:"";
                  icon = column.fmtParam.icon?column.fmtParam.icon:"glyphicon glyphicon-link";
                  title = column.fmtParam.title?column.fmtParam.title:"Ouvrir le lien";    
                  urls.push("<a href=\"" + url + "/" + id + "\" target=_blank class='tips' title='"+title+
                            "'><button class='tips btn btn-xs btn-primary'><span class='glyphicon "+icon+"''></span></button></a>");
                }
              });
              return urls.join(', ');
    },
    "picture": function(column, row) {
              url = row[column.id];
              if (!url) return url;
              customClass = column.fmtParam.customClass?column.fmtParam.customClass:"";
              if(!customClass) 
                return `<img class='thumbnail' src='${url}' alt='Photo manquante'>`;
              else
                return `<img class='${customClass}' src='${url}' alt='Photo manquante'>`;
    },
    "files": function(column, row) {
      id = row[column.id];
      if (!id || !column.fmtParam) return id;

      var fname = row.filename;
      var comments = row.comments?row.comments.replace("'", '&#39;'):"";

      var type = { image:["png", "jpeg", "jpg", "gif", "ico"], pdf: ["pdf"], movie: ["mp4"], excel: ["xls", "xlsx"] };
      var ext = null;

      if (fname) {
        ext = fname.slice((fname.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
      }
      else {
        return 'manque colonne filename';
      }

      var url = column.fmtParam.url?column.fmtParam.url:"";

      if(type.pdf.indexOf(ext) != -1) {
        var title = "<div class=\"top-5 text-center\"><img src=\"/media/icon/download_pdf.png\"></div>";
        return "<a href=\"" + url + "/" + id + "\"><button class='tips btn btn-xs btn-info' title='"+title+"'><span class='glyphicon glyphicon-floppy-save'></span></button></a>";
      } else if(type.excel.indexOf(ext) != -1) {
        var title = "<div class=\"top-5 text-center\"><img src=\"/media/icon/download_excel.png\"></div>";
        return "<a href=\"" + url + "/" + id + "\"><button class='tips btn btn-xs btn-info' title='"+title+"'><span class='glyphicon glyphicon-floppy-save'></span></button></a>";
      } else if(type.image.indexOf(ext) != -1) {
        return '<a data-type="image" data-fancybox="album_image" data-caption="<b>'+fname+'</b><br/>'+comments+'" href="' + url + '/' + id + '" class="js_files"> \
                  <button class="tips btn btn-xs btn-primary"><i class="fa fa-camera"></i></button></a>';
      } else if(type.movie.indexOf(ext) != -1) {
        return '<a data-type="video" href="' + url + '/' + id + '" class="js_files_mp4"> \
                  <button class="tips btn btn-xs btn-primary"><i class="fa fa-film"></i></button></a>';
      }

    },
    "date": function(column, row) {
              date = row[column.id];
              if (!date) return date;

              return moment(date).format('DD/MM/YYYY');
    },
    "datetime": function(column, row) {
              date = row[column.id];
              if (!date) return date;
              if(column.id == 'modif_dt' || column.id == 'updated_at') {
                return `<span data-row-id='${row.rid}' class='command-log link' title='Historique des modifications'>${moment(date).format('DD/MM/YYYY HH:mm:ss')}</span>`;                
              }
              else {
                return moment(date).format('DD/MM/YYYY HH:mm:ss');
              }
    },
    "numeric": function(column, row) {
              number = row[column.id];
              if (!number) return 0;

              return number;
    },
    "km": function(column, row) {
              number = row[column.id];
              if (!number) return 0;

              return parseFloat(number/1000, 2).toFixed(2);
    },
    "hour": function(column, row) {
              number = row[column.id];
              if (!number) return 0;

              return parseFloat(number/3600, 2).toFixed(2);
    },
    "bold": function(column, row) {
              value = (row[column.id]!="null")?row[column.id]:"";         
              return "<b class='"+column.fmtParam+"'>" + value + "</b>";
    },
    "css": function(column, row) {              
              value = row[column.id];
              if (!column.fmtParam || !value) return value;
              
              return "<div class='parent-size text-center " + column.fmtParam + value.toLowerCase() + "'><b>" + value + "</b></div>";
    },
    "tips": function(column, row) {
              value = row[column.id];
              if (!column.fmtParam || value === null || row[column.fmtParam] === null) return value;
              
              return "<a href=# class='tips' title='" + row[column.fmtParam] + "'>" + value + "</a>";
    },
    "shorten": function(column, row) {
      value = row[column.id];
      if (!column.fmtParam || value === null || row[column.fmtParam] === null) return value;
      short_value = value.substring(0,column.fmtParam);
      suffix = (value.length > column.fmtParam)?"...":"";
      return "<span class='tips' title='" + _.replace(value,new RegExp("'","g"),"&#39;") + "'>" + short_value + suffix +"</span>";
    },
    "edit_files": function(column, row) {
      if (row.nb_fjs == 0) return null;

      if (crud_rights.indexOf("u") != -1 && (row.locked == 'undefined' || !row.locked))
        button = "<button type='button' title='Editer' class='tips btn btn-xs btn-primary command-edit' data-row-id='" + row.rid + 
                 "' data-open-tab='attach_view'><span class='fa fa-paperclip fa-bs right-2'></span>" + row.nb_fjs + "</button> ";
      else
        button = "<span class='fa fa-paperclip fa-bs'></span>" + row.nb_fjs;

        return button;
    },    
    "view_notes": function(column, row) {
      if (row.nb_notes == 0) return null;

      if (crud_rights.indexOf("u") != -1 && (row.locked == 'undefined' || !row.locked))
        button = "<button type='button' title='Editer' class='tips btn btn-xs btn-primary command-edit' data-row-id='" + row.rid + 
                 "' data-open-tab='notes_view'><span class='fa fa-comments fa-bs right-2'></span>" + row.nb_notes + "</button> ";
      else
        button = "<span class='fa fa-comments fa-bs'></span>" + row.nb_notes;

        return button;
    }
  }
}

function _grid_template(crud_rights, config) {
  // configuration de base
  var $config = {
      'search': 'yes',
         'add': 'yes',
      'import': 'yes',
       'excel': 'yes',
      'filter': 'yes',
        'info': 'yes',
         'map': 'yes',
  };

  if(!crud_rights) crud_rights = "";

  // surcharge de configuration donnée en paramètre
  if(config) {
    if(config.search) $config.search = config.search;
    if(config.add)    $config.add = config.add;
    if(config.import) $config.import = config.import;
    if(config.excel)  $config.excel = config.excel;
    if(config.filter) $config.filter = config.filter;
    if(config.info)   $config.info = config.info;
    if(config.map)    $config.map = config.map;
  }
  
  // validation de configuration selon la route
  if (crud_rights.indexOf("c") == -1) $config.add = 'no';
  if (crud_rights.indexOf("i") == -1) $config.import = 'no';
  if (crud_rights.indexOf("e") == -1) $config.excel = 'no';
  if (crud_rights.indexOf("g") == -1) $config.map = 'no';

  var search_button = '';
    if($config.add == 'yes')    search_button += ' <button type="button" title="Ajouter un élement" class="tips btn btn-primary command-create"><span class="glyphicon glyphicon-plus" aria-hidden="true"></span></button>';
    if($config.import == 'yes') search_button += ' <a target="_blank" class="btn btn-info command-import" role="button" href="' + route + '/import"><span class="fa fa-upload fa-bs" aria-hidden="true"></span></a> ';
    if($config.excel == 'yes')  search_button += ' <button type="button" title="Export Excel" class="tips btn btn-success command-export"><i class="fa fa-download fa-bs"></i></span></button> ';
    if($config.map == 'yes')    search_button += ' <button type="button" title="Visu carto" class="tips btn btn-success command-map"><i class="fa fa-globe fa-bs"></i></span></button> ';

  if( $("#modal_search span#nosearch").length == 0 && $config.search =='yes') {
    search_button += ' <button type="button" title="Critères de recherche" class="tips btn btn-warning command-search"><i class="fa fa-filter fa-bs"></i></span></button> ';
  }

  if($config.filter == 'yes') {
    search_button += '<div class="{{css.search}}"> \
                          <div class="input-group"> \
                            <span class="{{css.icon}} input-group-addon {{css.iconSearch}}"></span> \
                            <input type="text" title="Filtre sur l\'ensemble des champs visibles" class="{{css.searchField}} tips" placeholder="{{lbl.search}}" /> \
                          </div> \
                      </div>';
  }

  var info = '';
  if($config.info == 'yes') {
    info = '<div class="pull-right">{{lbl.infos}}</div>';
  }

  return {
          actionButton: '<button class="btn btn-default" type="button" title="{{ctx.text}}">{{ctx.content}}</button>',
          actionDropDown: '<div class="{{css.dropDownMenu}}"> \
                            <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown"> \
                              <span class="{{css.dropDownMenuText}}">{{ctx.content}}</span> \
                              <span class="caret"></span> \
                            </button> \
                            <ul class="{{css.dropDownMenuItems}}" role="menu"> \
                            </ul> \
                          </div>',

          actionDropDownItem: '<li><a data-action="{{ctx.action}}" class="{{css.dropDownItem}} {{css.dropDownItemButton}}">{{ctx.text}}</a></li>',
          actionDropDownCheckboxItem: '<li><label class="{{css.dropDownItem}}"><input name="{{ctx.name}}" type="checkbox" value="1" class="{{css.dropDownItemCheckbox}}" {{ctx.checked}} /> {{ctx.label}}</label></li>',
          actions: '<div class="{{css.actions}}"></div>',
          body: '<tbody></tbody>',
          cell: '<td fmtParam="{{ctx.fmtParam}}" title="{{ctx.title}}" class="{{ctx.css}}" style="{{ctx.style}}">{{ctx.content}}</td>',
          header: `<div id="{{ctx.id}}" class="{{css.header}}"> 
                    <div class="row"> 
                      <div class="actionBar"> 
                        <p class="{{css.search}}"></p> 
                        <p class="{{css.actions}}"></p> 
                      </div> 
                      <div id="qte_list">
                        <p  class="{{css.infos}}"></p>
                      </div>
                    </div> 
                   </div>`,
          footer: '<div id="{{ctx.id}}" class="{{css.footer}}"> \
                     <div class="row"><div class="col-sm-12"> \
                        <p class="{{css.pagination}}"></p> \
                     </div> \
                   </div>',

          headerCell: '<th data-column-id="{{ctx.column.id}}" class="{{ctx.css}}" style="{{ctx.style}}"><a href="javascript:void(0);" class="{{css.columnHeaderAnchor}} {{ctx.sortable}}"><span class="{{css.columnHeaderText}}">{{ctx.column.text}}</span>{{ctx.icon}}</a></th>',
          icon: '<span class="{{css.icon}} {{ctx.iconCss}}"></span>',
          infos: info,
          loading: '<tr><td colspan="{{ctx.columns}}" class="loading">{{lbl.loading}}</td></tr>',
          noResults: '<tr><td colspan="{{ctx.columns}}" class="no-results">{{lbl.noResults}}</td></tr>',
          pagination: '<ul class="{{css.pagination}}"></ul>',
          paginationItem: '<li class="{{ctx.css}}"><a data-page="{{ctx.page}}" class="{{css.paginationButton}}">{{ctx.text}}</a></li>',
          rawHeaderCell: '<th class="{{ctx.css}}">{{ctx.content}}</th>', // Used for the multi select box
          row: '<tr{{ctx.attr}}>{{ctx.cells}}</tr>',
          search: search_button,
          select: '<input name="select" type="{{ctx.type}}" class="{{css.selectBox}}" value="{{ctx.value}}" {{ctx.checked}} />'
  };
}